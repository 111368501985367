//import React from "react"
//import bugsnag from '@bugsnag/js'
//import bugsnagReact from '@bugsnag/plugin-react'

export const onServiceWorkerUpdateReady = () => {
    window.location.reload()
}
/*
export const wrapRootElement = ({ element }) => {
  const bugsnagClient = bugsnag(process.env.GATSBY_RECAPTCHA_KEY)
  bugsnagClient.use(bugsnagReact, React)
  const ErrorBoundary = bugsnagClient.getPlugin('react')
  return (
    <ErrorBoundary>
      {element}
    </ErrorBoundary>
  )
}
*/